<template>
	<div>
		<p
			style="
				font: normal normal bold 14px/19px Nunito;
				letter-spacing: 0px;
				color: #525355;
			"
			class="mt-5"
		>
			<slot name="heading"> </slot>
		</p>
		<v-container class="pa-0"
			><v-row>
				<v-col class="d-flex" cols="6" sm="6">
					<v-select
						v-model="value.period"
						:items="['Monthly', 'Yearly']"
						outlined
						hide-details="auto"
					></v-select>
				</v-col>
				<v-col class="d-flex" cols="6" sm="6">
					<v-text-field
						prefix="£"
						outlined
						v-model="value.amount"
						placeholder="0"
						hide-details="auto"
						v-maska:[options]
					></v-text-field>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
export default {
	name: "J3Monthly",
	data() {
		return {
			options: {
				//mask: "9,99#.##",
				mask: "9,99#",
				tokens: {
					9: { pattern: /[0-9]/, repeated: true },
				},
				reversed: true,
			},
		};
	},
	props: {
		question: {
			type: String,
			default: "",
		},
		value: {
			type: Object,
			default() {
				return { period: "Monthly", amount: "" };
			},
		},
	},
	watch: {
		value: {
			handler(val, oldVal) {
				this.$emit("input", val);
			},
			deep: true,
		},
	},
	computed: {},
};
</script>
